<template>
  <Logos2 title="Certificates" :logos="logos" />
</template>

<script>
import Logos2 from "@/components/ui/landing/logos/Logos2.vue";

export default {
  components: {
    Logos2,
  },
  data() {
    return {
      logos: [
        {
          image: require("@/assets/images/logos/gmpLogo.png"),
          link: "https://lic-hub.customs.gov.jo/JCAttachments/B98E3237E41411ED8613005056AAF794%5C469261FA-DC2A-4538-8C2D-F08E85E1C830.signed.PDF",
          name: "GMP",
        },
        {
          image: require("@/assets/images/logos/ISO1png.png"),
          link: "https://www.iafcertsearch.org/certification/eb88a9dd-3ab2-5fa0-a952-de1dc8e50a6f",
          name: "ISO",
        },
        // }, {
        //   image: require('@/assets/images/logos/freesales.jpg'),
        //   link: 'https://drive.google.com/file/d/1knVlV8TZvpMX-SWD5vJoHr4i8Yiekmjc/view?usp=sharing',
        //   name:"fsc"
        // }
      ],
    };
  },
};
</script>
