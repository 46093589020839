<template>
  <v-sheet class="hero9-bg d-flex flex-column flex-lg-row">
    <div class="d-sm-flex col-sm-12">
      <div class="col-sm-5 row">
        <div class="col">
       
        </div>
        <div class="col">
          <slaedshow />
        </div>
      </div>

      <div class="badge bg-primary text-break col-sm-7">
        <v-responsive>
          <div
            class="text-uppercase text-lg-h3 Textstyle1 font-weight-bold mt-4 mb-4"
          >
          Introduction:
          </div>

          <div class="mb-4 font-weight-bold Textstyle text-lg-h6">
             Innovation is a leading medical diagnostic company
            dedicated to providing accurate and reliable diagnostic solutions
            for healthcare professionals and patients alike. With a commitment
            to cutting-edge technology, precision, and patient-centric care, we
            strive to enhance medical outcomes and contribute to the advancement
            of healthcare. Mission: Our mission is to empower healthcare
            providers with state-of-the-art diagnostic tools, enabling early and
            accurate detection of diseases. We aim to improve patient care,
            reduce healthcare costs, and promote a healthier society through
            innovation and excellence in diagnostics. Vision: To be the
            preferred partner of healthcare professionals worldwide by
            delivering unmatched diagnostic accuracy, convenience, and
            expertise. History: Founded in 2015, Innovation Diagnostics emerged
            as a response to the growing need for advanced and reliable
            diagnostic solutions. Over the years, we have developed a reputation
            for precision, integrity, and a commitment to staying at the
            forefront of medical technology.
            <div
            class="text-uppercase text-lg-h3 Textstyle1 font-weight-bold mt-4 mb-4"
          >
          Products and Services:
          </div>
            <ul>
              <li>
                diazone Company for Laboratory Diagnostics aims to use the local
                raw materials in the national manufacture, employs the local
                human beings and benefits the technical abilities of the Arab
                scientists.
              </li>
              <li>
                The Company aims to supply the laboratories with products of
                highest quality and customized to meet the universal
                specification and cost effectiveness.
              </li>
              <li>
                diazone Company for Clinical Laboratory Diagnostics receive the
                customer notes and negotiate with them the best way for
                obtaining correct results, advise them and train some of their
                employee, maintain their instruments and guide them for the
                user’s technical faults.
              </li>
            </ul>

            <!-- <div class="mt-4 mb-2 font-weight-bold">Building your project</div> -->
          </div>
        </v-responsive>
      </div>
    </div>
  </v-sheet>
</template>
<script>
import slaedshow from "@/components/ui/landing/card/slaedshow.vue";

export default {
  components: {
    slaedshow,
  },
  data() {
    return {};
  },
};
</script>

<style scope>
.image {
  width: 55%;
}
.Textstyle {
  color: #1b4f7c;
}
.Textstyle1 {
  color: #c0253c;
}
</style>