<template>
  <v-sheet>
    <Toolbar3 is-fluid />
    <div class="pt-2">
      
      <!-- <v-text-field
              ref="search"
              class="hidden-xs-only mx-2 bg-deep-purple pa-3"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              filled
              dense
              style="max-width: 600px"
              rounded
              v-model="search"
            ></v-text-field> -->

            <v-spacer class="d-block d-sm-none"></v-spacer>

            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
      <Tabs1 :productList="ProductList" v-if="ProductList" :items="Items"  />

    </div>
    <Footer4Demo />
  </v-sheet>
</template>

<script>
import Toolbar3 from "@/components/ui/landing/toolbar/Toolbar3.vue";
import Tabs1 from "@/components/ui/vuetify/components/Tabs1Demo.vue";
import Footer4Demo from "@/components/ui/landing/footer/Footer4Demo.vue";
// import Logos1Demo from '@/components/ui/landing/logos/Logos3Demo.vue'
import Card1Demo from "@/components/ui/landing/card/Card1Demo.vue";
import { productList as ProductList } from "./product.js";
import { items as Items } from "./productTitle.js";


export default {
  watch: {
    // whenever question changes, this function will run
     search(newQuestion, oldQuestion) {
      if (oldQuestion !== '') {
        console.log(newQuestion)
        this.Tayp=[]
        this.Tayp=this.productList
      }
    }
  },
  data() {
    return {
      ProductList,
      Items,
      search: "",
    };
  },

  components: {
    Toolbar3,
    Tabs1,
    Footer4Demo,
    Card1Demo,
  },
};
</script>

