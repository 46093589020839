<template>
  <v-sheet >
    <Toolbar3 is-fluid />
    <Hero5 />
  </v-sheet>
</template>

<script>
import Hero5 from '@/components/ui/landing/hero/Hero5.vue'
import Toolbar3 from '@/components/ui/landing/toolbar/Toolbar3.vue'

export default {
  components: {
    Hero5,
    Toolbar3
  }
}
</script>
