<template>
  <Team1 :team="team" />
</template>

<script>
import Team1 from '@/components/ui/landing/team/Team1.vue'

export default {
  props:{
    team:Array
  },
  components: {
    Team1
  }
}
</script>
