<template>
  <v-sheet  >

    <Hero3 />
  </v-sheet>
</template>

<script>
// import Toolbar3 from '@/components/ui/landing/toolbar/Toolbar3.vue'
import Hero3 from '@/components/ui/landing/hero/Hero3.vue'

export default {
  components: {
    Hero3
  }
}
</script>


