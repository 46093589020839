<template>
  <div class="col-sm-12 "  v-cloak >
    <!-- <v-cloak/> -->
    <v-carousel 
    hide-delimiters
      cycle
      interval="10000"
      height="auto"
      width="auto"
      v-cloak
     
    >
    <router-link v-for="item  in items" :key="item.Name" :to="{ name: 'Product-page', params: {productTab:item.key}}" class=" align-center text-decoration-none mr-2  #1b4f7c">
   

        <v-carousel-item :key="item.Name"  :src="require(`/public/images/slaedimage/${item.Name}`)"  eager cover 
          contain max-width="700" max-height="500" aspect-ratio="1" >

        </v-carousel-item >
  </router-link>

       
        <!-- <v-carousel-item src="@/assets/images/categories/BG.png" max-width="900" max-height="700"  ></v-carousel-item> -->

    </v-carousel>
  </div>
</template>
<script>
export default {
 data(){
  return {
    items:[
      
      {Name:"Cholesterol CHOD POD.2(mid).jpg",key:"0"},
      {Name:"CRP LATEX.2(mid).jpg",key:"3"},
      {Name:"Glucose GOD POD.2(mid).jpg",key:"0"},
      {Name:"GOT (AST).2(mid).jpg",key:"0"},
      {Name:"Hematology Reagents Kit.2 (mid).jpg",key:"5"},
      {Name:"Monoscan Groupage .2 (mid).jpg",key:"8"},
      {Name:"Tests-fotor(mid).jpg",key:"4"},
      {Name:"Turbilatex Reagents 01.2 (mid).jpg",key:"7"},
      {Name:"Turbilatex Reagents 02.2(mid).jpg",key:"7"},
      {Name:"Urine Strips.2mid).jpg",key:"6"},
      // {Name:"Antibiotic Sensitivity Discs.2.jpg",key:"1"},
      
   

    ]

  }
 } 
}
</script>
<style>
.v-carousel .v-carousel-item {
  position: fixed;
    width: 50%;

}
</style>