<template>
  <Contact2 />
</template>

<script>
import Contact2 from '@/components/ui/landing/contact/Contact2.vue'

export default {
  components: {
    Contact2
  }
}
</script>
