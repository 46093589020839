<template>
  <v-sheet>
    <v-container class="py-6 py-lg-8">
      <div class="d-flex">
        <v-divider class="my-2"></v-divider>
        <div class="mx-1 overline Textstyle font-weight-bold">PRODUCTS</div>
        <v-divider class="my-2"></v-divider>
      </div>
    
      <div class="text-center">
   
        <!-- <h2 class="text-h6 text-lg-h4 Textstyle">PRODUCTS</h2> -->
        <!-- <v-divider class="my-3"></v-divider> -->
      </div>
      <v-row class="mt-0">
        <v-col
          v-for="(item) in team"
          :key="item.key"
          cols="12"
          sm="10"
          lg="1"
          
          class="text-center ml-5 "
        >
        <router-link :to="{ name: 'Product-page', params: { productTab:item.key}}" class=" align-center text-decoration-none mr-2  #1b4f7c">

          <v-avatar size="100"  rounded="1">
            <v-img
            :src="require(`/public/images/slaedimage/${item.image}`)"
            eager
            cover
            contain
            alt=""
            class="m-2"
            />
          </v-avatar>
          <div class=" font-weight-bold  mt-xl-1 Textcolor ">
          {{item.tab}}
        </div>
      </router-link>


        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props:{
    team:Array
  },

 
}
</script>
<style  >
.TextBackgrund {
  background-color: #ffffff;

}

.TextBackgrund {
  color: #c0253c;
}
.Textcolor {
  color: #1b4f7c;
  font-size: .7em;
 
}
.toolbar3-min-width {
  min-width: 230px;
}
</style>