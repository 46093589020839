<template>
  <v-sheet color="transparent">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col v-for="(item, i) in features" :key="i" cols="12" sm="6" lg="4">
          <v-card class="py-4 px-2" min-height="300" variant="outlined">
            <v-responsive max-width="300" class="mx-auto text-center">
              <div class="mb-2">
                <div class="text-h4 Textstyle1">{{ item.icon }}</div>
              </div>
              <div class="mt-2 Textstyle font-weight-bold">
                {{ item.description }}
              </div>
            </v-responsive>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      features: [
        {
          icon: "History",

          description:
            " Founded in 2015, Innovation Diagnostics emerged as a response to the growing need for advanced and reliable diagnostic solutions. Over the years, we have developed a reputation for precision, integrity, and a commitment to staying at the forefront of medical technology.",
        },
        {
          icon: "Mission",

          description:
            " Our mission is to empower healthcare providers with state-of-the-art diagnostic tools, enabling early and accurate detection of diseases. We aim to improve patient care, reduce healthcare costs, and promote a healthier society through innovation and excellence in diagnostics.",
        },
        {
          icon: "Vision",

          description:
            "To be the preferred partner of healthcare professionals worldwide by delivering unmatched diagnostic accuracy, convenience, and expertise. ",
        },
      ],
    };
  },
};
</script>
<style scope>
.image {
  width: 55%;
}
.Textstyle {
  color: #1b4f7c;
}
.Textstyle1 {
  color: #747188;
}
</style>