<template>
  <div class="d-flex flex-column flex-md-row " >
    
    
    <div class="w-full w-md-half d-flex align-center">
      
      <v-container class="pa-6 pa-md-6">
        <v-img
            src="@/assets/images/innovation.png"
            alt="innovation"
            class="image d-block"
          ></v-img> 
        <h6 class="text-h6   ma-4" sm="12">
          <span class="Textstyle font-weight-bold ">
          Innovation is a leading medical diagnostic company dedicated to
          providing accurate and reliable diagnostic solutions for healthcare
          professionals and patients alike. With a commitment to cutting-edge
          technology, precision, and patient-centric care, we strive to enhance
          medical outcomes and contribute to the advancement of healthcare.
        </span>
        </h6>
        
      </v-container>
    </div>
    <div class="w-full w-md-half ">
      <!-- <v-img
        :src="require('/public/images/BG1.PNG')"
        :max-height="$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 80px)' : '400'"
        alt=""
      ></v-img> -->
      <slaedshow />
    </div>
  </div>
</template>
<script>
import slaedshow from "@/components/ui/landing/card/slaedshow.vue";
export default {
  components: {
    slaedshow,
  },
};
</script>
<style >
.Textstyle {
  color: #1b4f7c;
}


</style>