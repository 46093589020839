import Vue from 'vue'
import Router from 'vue-router'
import contactuspage from '@/pages/ContactUsPage.vue'
import homePage  from '@/pages/HomePage.vue'
import productPage from '@/pages/Product.vue'
import defaultLayout from '@/layouts/DefaultLayout.vue'
import errorLayout from '@/layouts/ErrorLayout.vue'
Vue.use(Router)

export const routes = [
   
  {
    path: '/',
    component:defaultLayout,
    children: [{
      path: '',
      name: 'Home-page',
      component: homePage
    }
  ]},
  {
    path: '/contactUs/',
    component: defaultLayout,
    children: [{
      path: '',
      name: 'contactus-page',
      component: contactuspage,
    }
  ]
  },
  {
    path: '/product/:productTab',
    component: defaultLayout,
    children: [{
      path: '',
      name: 'Product-page',
      component: productPage
    }
  ]

  },
 {
    path: '/:notFound(.*)',
    component: errorLayout,
     name: 'error',
      component: defaultLayout
  }

]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL ,
  scrollBehavior(to, from, savedPosition) {
    console.log(to ,from , savedPosition)
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0 }
  },
  routes
})
/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
// router.afterEach((to, from) => {
//   return next()
// })

export default router
