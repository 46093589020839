<template>
<v-sheet>
 <Toolbar3/>
 <div class="d-flex ">
 
  <Feature6 />
  </div>
</v-sheet>
</template>

<script>
import Feature6 from '@/components/ui/landing/feature/Feature6.vue'
import Toolbar3 from '@/components/ui/landing/toolbar/Toolbar3.vue'



export default {
  components: {
    Feature6,
    Toolbar3,
   
    
  }


}
</script>
<style scoped>
.hero9-bg {
  background-image: url("/images/BG1.PNG"); 
  background-size: cover;
  
 
}

</style>
