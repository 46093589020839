<template>
  <div class="div">
    <Hero5Demo />
    <feature10Demo/>
    <!-- <feature6Demo /> -->
    <!-- <Hero3Demo /> -->
    <Team1Demo :team="Items"/>
    <Logos1Demo />

    <Footer4Demo />
  </div>
</template>

<script>
import Hero3Demo from "@/components/ui/landing/hero/Hero3Demo.vue";
import Footer4Demo from "@/components/ui/landing/footer/Footer4Demo.vue";
import Logos1Demo from "@/components/ui/landing/logos/Logos2Demo.vue";
import feature6Demo from "@/components/ui/landing/feature/Feature6Demo.vue";
import feature10Demo from "@/components/ui/landing/feature/Feature10Demo.vue";
import Team1Demo from "@/components/ui/landing/team/Team1Demo.vue";

import Hero5Demo from "@/components/ui/landing/hero/Hero5Demo.vue";
import { items as Items } from "./productTitle.js";


export default {
  components: {
    feature6Demo,
    Hero3Demo,
    Footer4Demo,
    Logos1Demo,
    Hero5Demo,
    feature10Demo,
    Team1Demo
    
  },
  data(){
    return{
      Items
    }
  }
};
</script>
<style >
.div {
  background-color: transparent;
}
</style>