<template>
  <div>
    <!-- <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader  class="text-uppercase font-weight-bold Hero9-bg">Menu</v-subheader>
        <v-list-item v-for="(item, index) in menu" :key="index" :to="item.link">
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
       
      </template>
    </v-navigation-drawer>

    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn> -->

    <v-app-bar flat height="100" color="transparent" class="py-0 px-0 ">
      <v-container class="py-0 px-0 px-sm-4 fill-height d-flex ">
        <router-link to="/" class="d-flex align-center text-decoration-none mr-4 toolbar3-min-width #1b4f7c">
          <img src="@/../favicon.png" alt="diazone" height="50" >
          <!-- <span class="font-weight-black text-uppercase">
            diazone
          </span> -->
        </router-link>

        <v-spacer></v-spacer>

        <div class="pl-10  ma-0"  >
          <v-btn
            v-for="(item, index) in menu"
            :key="index"
            :to="item.link"
            text
            color="#1b4f7c"
            
          >
            {{ item.title }}
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <div class="toolbar3-min-width text-right px-sm-4   ">
          <v-row>
         
         
          <div class="d-flex mt-2 mx-2 ">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-phone-outline</v-icon>
        
            <a href="https://wa.link/7rzjsl" class="text-decoration-none secondary--text text--lighten-2">Whatsapp : +962775907079</a>
          </div>
          <div class="d-flex mt-2 ">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-email-outline</v-icon>
            <a href = "mailto:sales@diazone.fr?subject = Feedback&body = Message" class="text-decoration-none secondary--text text--lighten-2">
           sales@diazone.fr</a>
          </div>
        </v-row>
        </div>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { menu2 as menu } from './menus.js'

export default {
  data() {
    return {
      drawer: null,
      menu
    }
  }
}
</script>

<style scoped>
.drawer-button {
  position: static;
  top: 64px;
  bottom: 100px;
  left: -22px;
  z-index: 6;
}

.toolbar3-min-width {
  min-width: 230px;
}

</style>

