<template>
  <v-container
    fluid
    class="TextBackgrund h-full d-flex flex-column backgrond mx-auto hero9-bg container"
  >
  
    <!-- <h2 class="text-h1 text-Right white--text font-weight " v-if="Tab">{{Tab}}</h2> -->
    <!-- <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field> -->
    <div class="d-flex flex-column flex-md-row">
      <div class="w-full w-md-half d-flex align-center">
        <div class="pa-md-10">
          <h2
            class="text-h4 text-lg-h4 mt-0 mt-xl-3 text-Right font-weight TextBackgrund"
            v-if="Tab"
          >
            {{ Tab }}
          </h2>
          <div class="font-weight-bold text-lg-h6 mt-1 Textcolor" v-if="Text">
            {{ Text }}
          </div>
          <div class="mt-3">
            <a
              :href="Catalogue"
              class="text-decoration-none secondary--text text--lighten-2"
            >
              <v-btn class="" outlined large color="#1b4f7c">
                {{ Tab }}-Catalogue
              </v-btn>
            </a>
          </div>
        </div>
      </div>
      <div class="w-md-half">
        <div class="card col-5" v-if="image === 'stains1.png'">
          <v-img
            :src="require(`/public/images/slaedimage/${image}`)"
            eager
            cover
            contain
            alt=""
            class="m-2"
          >
          </v-img>
        </div>
        <div v-else class="d-flex justify-center ma-4 col-9">
          <v-img
            :src="require(`/public/images/slaedimage/${image}`)"
            eager
            cover
            contain
            class="img"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="d-flex">
      <v-divider class="my-2"></v-divider>
      <div class="mx-2 overline">{{ Tab }}</div>
      <v-divider class="my-2"></v-divider>
      
    </div>
    <v-row v-if="Tab != 'HEMATOLOGY REAGENTS KIT'">
      
      <v-col v-for="(open, i) in Tayp" :key="i" cols="12" md="4">
        <Card1 v-bind="open" />
      </v-col>
    </v-row>
    <v-col v-if="Tab == 'HEMATOLOGY REAGENTS KIT'">
      <v-row v-for="(open, i) in Tayp" :key="i">
        <v-col
          class="Text font-weight-bold text-h6 text-lg-h6"
          cols="12"
          md="12"
          v-if="open.DESCRIPTION[1]"
        >
          {{ open.DESCRIPTION[0] }}</v-col
        >
        <v-col
          class="text font-weight-bold text-h6 text-lg-h6"
          cols="12"
          md="12"
          v-if="open.part"
          >{{ open.part }}</v-col
        >

        <v-col v-for="(SS, ii) in open.Name" :key="ii">
          <Card1Hem :Name="SS" />
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import Card1 from "@/components/ui/landing/card/Card1.vue";
import Card1Hem from "@/components/ui/landing/card/Card1Hem.vue";

export default {
  watch: {
    // whenever question changes, this function will run
    //  search(newQuestion, oldQuestion) {
    //   if (oldQuestion !== '') {
    //     console.log(newQuestion)
    //     this.Tayp=[]
    //     this.Tayp=this.productList
    //   }
    // }
  },

  mounted() {
    const arr = [];
    for (let u = 0; u < this.productList.length; u++) {
      const element = this.productList[u].tags[0];
      if (element === this.Tab) {
        this.Tayp.push(this.productList[u]);
        // Console.log(this.Tayp)
      }
    }
  },

  props: {
    Tab: String,
    productList: Array,
    Text: String,
    image: String,
    Catalogue: String,
  },
  components: {
    Card1,
    Card1Hem,
  },
  data() {
    return {
      Tayp: [],
      search: "",
    };
  },
};
</script>
<style  scoped>
.TextBackgrund {
  background-color: #ffffff;
}

.TextBackgrund {
  color: #c0253c;
}
.Textcolor {
  color: #1b4f7c;
}
.Text {
  background-color: #f0aa45;
  text-align: center;
  margin-bottom: 1cm;
  color: black;
  height: 5ch;
}
.text {
  background-color: #138944;
  color: #ffffff;
  text-align: center;
  height: 5ch;
}
.TextA {
  color: aliceblue;
}
.card {
  /* margin-top: 4cm; */

  margin-left: 5cm;
  color: "transparent";
}
</style>