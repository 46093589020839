<template>
  <v-sheet color="transparent">
    <v-container>
      <div class="d-flex">
        <v-divider class="my-2"></v-divider>
        <div class="mx-2 overline">{{ title }}</div>
        <v-divider class="my-2"></v-divider>
      </div>

      <div class="text-center my-4">
        <a v-for="(logo, index) in logos" :key="index" :href="logo.link">
          <img
            :src="logo.image"
            :class="[logo.name !== 'ISO' ? 'img' : 'img1']"
          />
        </a>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    logos: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.img {
  filter: grayscale(100%) brightness(100%);
  width: 123px;
}
.img1 {
  filter: grayscale(100%) brightness(100%);
  width: 120px;
  padding-bottom: 1.9ch;
}

.theme--dark img {
  filter: grayscale(100%) invert(1);
}

.img:hover {
  filter: none;
}
.theme--dark img1 {
  filter: grayscale(100%) invert(1);
}

.img1:hover {
  filter: none;
}
</style>
