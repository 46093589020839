<template>
  <Feature10 />
</template>

<script>
import Feature10 from '@/components/ui/landing/feature/Feature10.vue'

export default {
  components: {
    Feature10
  }
}
</script>
