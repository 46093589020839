<template>
  <v-card   >
    <v-tabs v-model="tab" background-color="#1b4f7c" dark grow>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="">
      <v-tab-item
        v-for="item in items"
        :key="item.tab"
        background-color="#c0253c"
      >
        <v-card flat class="hero9-bg">
          <!-- <v-card-text >{{ item.content }}</v-card-text> -->

          <card1Demo
            :Tab="item.tab"
            :Catalogue="item.Catalogue"
            :productList="productList"
            v-if="item.tab && productList"
            :Text="item.Text"
            :image="item.image"
          />
          <!-- <Datatable4Demo :openings="openings" v-if="item.tab && openings"/> -->
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
// import card2Demo from '@/components/ui/landing/team/Team7Demo.vue'
import card1Demo from "@/components/ui/landing/card/Card1Demo.vue";

export default {
  created(){
  this.tab= parseInt(this.$route.params.productTab)
  // this.$route.query.key = value; 

},
  props: {
    productList: Array,
    items:Array,
    
  },
  components: {
    card1Demo,
  },
  data() {
    return {
      tab:null

     
    };
  },
};
</script>
<style scoped>
.hero9-bg {
  background-color: "transparent";
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
}
.productbar{
  position: absolute;
}
</style>