export const productList = [{
  Name: 'Cholesterol CHOD-PAP',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x20ml', Code: 'CH75.10.020' }, { Unite: '4×125ml', Code: 'CH76.04.125' }, { Unite: '4x50ml', Code: 'CH87.04.050' },]
}, {
  Name: 'CK-NAC',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '20x2.50ml', Code: 'CH111.20.025' }]
}, {
  Name: 'CK-NAC Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4x10ml +10ml', Code: 'CH104.40.010' }]
}, {
  Name: 'LDH DGKC',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '20×3ml', Code: 'CH24.20.003' }]
}, {
  Name: 'LDLc-D Direct',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '30+10 ml', Code: 'CH25.30.010' }]
}, {
  Name: 'Acid Phosphatase',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '18×2ml', Code: 'CH01.18.002' }]
}, {
  Name: 'Albumin BCG Colorimetric',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x250ml', Code: 'CHO2.02.250' }, { Unite: '2x50ml', Code: 'CH44.02.050' }]
}, {
  Name: 'Alkaline Phosphatase Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH03.10.015' }]
}, {
  Name: 'Alkaline Phosphatase Tablet',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH82.10.015' }]
}, {
  Name: 'Amylase',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '1x40ml', Code: 'CHO4.20.002' }, { Unite: '4×125ml', Code: 'CH76.04.125' }, { Unite: '4x50ml', Code: 'CH87.04.050' },]
}, {
  Name: 'Glucose GOD-PAP Colorimetric',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4x250ml', Code: 'CH90.04.250' }, { Unite: '4x50ml', Code: 'CH91.04.050' }]
}, {
  Name: 'Bilirubin Total / Direct DMSO',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x150ml', Code: 'CH05.02.150' }, { Unite: '2x125ml', Code: 'CH45.02.125' }, { Unite: '2x50ml', Code: 'CH60.02.050' }, { Unite: '6X50ml', Code: 'CH67.06.050' }]
}, {
  Name: 'Bilirubin Total / Direct Jendrassik-Gröff',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x125ml', Code: 'CH58.02.125' }, { Unite: '2x50ml', Code: 'CH59.02.050' }, { Unite: '6x50ml', Code: 'CH70.06.050' },]
}, {
  Name: 'Calcium Arsenazo III',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '3×50ml', Code: 'CHO6.03.050' }]
}, {
  Name: 'Calcium O-Cresolphtalein',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x150ml', Code: 'CH07.02.150' }, { Unite: '2x125ml', Code: 'CH46.02.125' }, { Unite: '6x50ml', Code: 'CH69.06.050' },]
}, {
  Name: 'Fructosamine NBT',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '19×3ml', Code: 'CH13.19.003' }]
}, {
  Name: 'Gamma GT Carboxy Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH8910.015' }]
}, {
  Name: 'GOT AST IFCC Kinetic Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50ml', Code: 'CH17.05.050' }]
}, {
  Name: 'GOT AST IFCC Kinetic UV Tablet',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH80.10.015' }, { Unite: '4×125ml', Code: 'CH76.04.125' }, { Unite: '4x50ml', Code: 'CH87.04.050' },]
}, {
  Name: 'GOT AST IFCC Kinetic Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH16.10.015' }, { Unite: '4×125ml', Code: 'CH76.04.125' }, { Unite: '4x50ml', Code: 'CH87.04.050' },]
}, {
  Name: 'GPT ALT IFCC Kinetic Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50ml', Code: 'CH19.05.050' }]
}
  , {
  Name: 'GPT ALT IFCC. Kinetic UV Tablet',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH81.10.015' }]
}, {
  Name: 'GPT ALT IFCC Kinetic Liquid',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x15ml', Code: 'CH18.10.015' }]
}, {
  Name: 'HD Precipitant',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4x5ml', Code: 'CH20.04.005' }]
}, {
  Name: 'HDLc-D Direct',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '30+10ml', Code: 'CH21.30.010' }]
}, {
  Name: 'Hemoglobin Drabkin',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4x5ml', Code: 'CH22.04.005' },]
}, {
  Name: 'Iron FerrozineC',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4x50ml', Code: 'CH23.04.050' }]
}, {
  Name: 'Dia80 Alkaline Detergent Reagents (CD80)',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500ml', Code: 'CH50.01.500' }, { Unite: '1L', Code: 'CH51.01.1000' }]
}, {
  Name: 'Magnesium Calmagite',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '6x50 ml', Code: 'CH83.06.050' }, { Unite: '2×150ml', Code: 'CH27.02.150' }, { Unite: '2x50ml', Code: 'CH52.02.050' },]
}, {
  Name: 'Phospholipids Trinder',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x10ml', Code: 'CH28.05.010' }]
}, {
  Name: 'Creatinine Jaffe Color-Kinetic',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x150ml', Code: 'CH12.02.150' }, { Unite: '2x50ml', Code: 'CH48.02.050' }, { Unite: '2x125ml', Code: 'CH61.02.125' },
  { Unite: '6x50ml', Code: 'CH68.06.050' },

  ]
}, {
  Name: 'Multicalibrator',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x5ml', Code: 'CH66.05.005' }]
}, {
  Name: 'Normal Control',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '1x5ml', Code: 'CH40.01.005' }, { Unite: '10x5ml', Code: 'CH6410.005' }]
}, {
  Name: 'Pathological Control',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '1x5ml', Code: 'CH42.01.005' }, { Unite: '10x5ml', Code: 'CH65.10.005' }]
}, {
  Name: 'Phosphorus UV',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x150ml', Code: 'CH29.02.150' }, { Unite: '2x125ml', Code: 'CH53.02.125' }, { Unite: '6x50 ml', Code: 'CH84.06.050' },]
}, {
  Name: 'Potassium TBP-Na',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x50ml', Code: 'CH30.02.050' }]
}, {
  Name: 'Sodium Mg-Uranylacetate',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '60ml', Code: 'CH31.00.060' }]
}, {
  Name: 'Total Protein Biuret',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x250ml', Code: 'CH32.02.250' },
  { Unite: '2x50ml', Code: 'CH54.02.050' }]
}, {
  Name: 'Triglycerides GPO-PAP',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x20ml', Code: 'CH33.10.020' },
  { Unite: '4x125ml', Code: 'CH34.04.125' },
  { Unite: '4x50ml', Code: 'CH55.04.50' },]
}, {
  Name: 'Urea B Berthelot',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4x125ml', Code: 'CH101.04.125' },
  { Unite: '4x50ml', Code: 'CH102.04.050' }],

  Name: 'Urea UV Urease -GLDH',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50ml', Code: 'CH36.05.050' },]
}, {
  Name: 'Uric Acid Uricase-POD',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10x20ml', Code: 'CH3710.020' },
  { Unite: '2x125ml', Code: 'CH38.02.125' },
  { Unite: '2x50ml', Code: 'CH57.02.050' },]
}, {
  Name: 'Urine & CSF Total protein',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x150ml', Code: 'CH39.02.150' },
  { Unite: '2x125ml', Code: 'CH62.02.125' },
  { Unite: '6x50ml', Code: 'CH71.06.050' },]
}, {
  Name: 'Lipase -LQ',
  tags: ['Biochemistry'],
  Insert: '',
  UnitsAndCode: [{ Unite: '2x20+8ml', Code: 'CH26.40.008' }]
}, {
  Name: [{ Name: "Diluent-SYS", Unite: '20L', Code: "1100100101A/3" },
  { Name: "Lyser-SYS-WH", Unite: '500ML', Code: "1100100202A/3" },
  { Name: "Cleaner-SYS-K", Unite: '100ML', Code: "1100100301A/3" }],
  tags: ['HEMATOLOGY REAGENTS KIT'],
  Insert: ['', '', ''],
  DESCRIPTION: ["SYSMEX", 1],
  part: "SYSMEX KX21 / N21 POCH-100i / POCH-80i (3part)"
}, {
  Name: [{ Name: "DILUENT-SYS", Unite: '20L', Code: "1100100102A/5" },
  { Name: "LYSER-SYS-SLS", Unite: '500ML', Code: "1100100204A/5" },
  { Name: "LYSER-SYS-FBA", Unite: '5L', Code: "1100100206A/5" },
  { Name: "LYSER-SYS-4DL", Unite: '5L', Code: "1100100205A/5" },
  { Name: "LYSER-SYS-4DS", Unite: '42ML', Code: "1100100501A/5" },
  { Name: "CLEANER-SYS-k", Unite: '100ML', Code: "1100100301A/5" },
  ],
  tags: ['HEMATOLOGY REAGENTS KIT'],
  Insert: ['', '', ''],
  DESCRIPTION: ["SYSMEX", 0],
  part: "SYSMEX (XS-500i) (X+S- 800i) (XS-1000i) (XT-1800i) 5-Part"
}, {
  Name: [{ Name: "DILUENT-SYS", Unite: '20L', Code: "1100100102A/5" },
  { Name: "LYSER-SYS-SLS", Unite: '500ML', Code: "1100100204A/5" },
  { Name: "LYSER-SYS-FBA", Unite: '5L', Code: "1100100206A/5" },
  { Name: "LYSER-SYS-4DL", Unite: '5L', Code: "1100100205A/5" },
  { Name: "LYSER-SYS-4DS", Unite: '42ML', Code: "1100100501A/5" },
  { Name: "CLEANER-SYS-k", Unite: '100ML', Code: "1100100301A/5" },
  ],
  tags: ['HEMATOLOGY REAGENTS KIT'],
  Insert: ['', '', ''],
  DESCRIPTION: ["SYSMEX", 0],
  part: "SYSMEX (XS-500i) (X+S- 800i) (XS-1000i) (XT-1800i) 5-Part"
}, {
  Name: [{ Name: "Diluent-MR", Unite: '20L', Code: "1100200101A/3" },
  { Name: "Lyser-MR 30 CFL", Unite: '500ML', Code: "1100200202A/3" },
  { Name: "Rinse-MR", Unite: '5L', Code: "1100200301A/3" },
  { Name: "Cleaner-MR-PROBE", Unite: '100ML', Code: "1100200303A/3" },
  { Name: "Cleaner-MR-EZ", Unite: '100ML', Code: "1100200302A/3" },
  ],
  tags: ['HEMATOLOGY REAGENTS KIT'],
  Insert: ['', '', ''],
  DESCRIPTION: ["MINDRAY", 1],
  part: "MINDRAYBC-3200/3000/2800/2600/1800/3000plus (3part)"
}, {
  Name: [{ Name: "DILUENT M-53", Unite: '20L', Code: "1100200103A1/5" },
  { Name: "LYSER M-53LEQ(I)", Unite: '1L', Code: "1100200206A5/5" },
  { Name: "DYE M-53LE0(II)", Unite: '500ML', Code: "1100200502A2/5" },
  { Name: "LYSER M-53LH", Unite: '500ML', Code: "1100200207A2/5" },
  { Name: "CLEANER-MR-K", Unite: '100ML', Code: "1100200305A1/5" },
  { Name: "CLEANER II", Unite: '1L×4', Code: "1100200304A1/5" },


  ],
  tags: ['HEMATOLOGY REAGENTS KIT'],
  Insert: ['', '', ''],
  DESCRIPTION: ["MINDRAY", 0],
  part: "MINDRAY (BC5100) (5180) (5300( (5380) 5-Part"
}, {
  Name: 'Salmonelle Paratyphi AH',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.14.AH.100' }
  ]
}, {
  Name: 'Salmonelle Paratyphi AO',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.15.A0.100' }
  ]
}, {
  Name: 'Salmonelle Paratyphi BH',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL16.BH.100' }
  ]
}, {
  Name: 'Salmonelle Paratyphi BO',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SI.17.BO.100' },
  ]
}, {
  Name: 'Salmonelle Paratyphi CH',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.18.CH.100' },
  ]
}, {
  Name: 'Salmonelle Paratyphi CO',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL19.C0.100' },
  ]
}, {
  Name: 'Salmonelle typhi H',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SI.20.H.100' },
  ]
}, {
  Name: 'Salmonelle typhi o',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.21.TO.100' }
  ]
}, {
  Name: 'Toxo Latex Kit',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SI.22.O1.100' }
  ]
}, {
  Name: 'TPHA Latex Kit',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.15.01.100' }, { Unite: '200 Tests', Code: 'SL.15.01.200' }
  ]
}, {
  Name: 'ASO Latex Kit',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.01.01.100' }
    , { Unite: '200 Tests', Code: 'SL.01.01.200' }]
}, {
  Name: 'Brucella aborts Reagent',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.O3.01.100' }
  ]
}, {
  Name: 'Brucella melitensis Reagent',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.O4.01.100' }
  ]
}, {
  Name: 'CRP Latex Kit',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SL.05.01.100' }, { Unite: '200 Tests', Code: 'SL.06.01.200' }
  ]
}, {
  Name: 'Proteus OX19 Reagent',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'SI.07.01.005' }
  ]
}, {
  Name: 'Proteus OX2 Reagent',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'SI.08.01.005' }
  ]
}, {
  Name: 'Proteus OXK Reagent',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'SL.09.01.005' }
  ]
}, {
  Name: 'RE Latex Kit',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '200 Tests', Code: 'SL.14.01.200' }, { Unite: '100 Tests', Code: 'SI.10.01.100' }
  ]
}, {
  Name: 'Rose Bengal Latex',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SI11.01.100' }
  ]
}, {
  Name: 'PR Carbon Antigen',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '150 Tests', Code: 'SI.12.01.150' }
  ]
}, {
  Name: 'Rubella Latex Kit',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SI.13.01.100' }
  ]
}, {
  Name: 'VDRL',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '250 Tests', Code: 'SL23.01.250' }
  ]
}, {
  Name: 'Waaler Rose',
  tags: ['Serology Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'SI.24.01.100' }
  ]
}, {
  Name: 'Monoscan Anti-A',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IMO4' }
  ]
}, {
  Name: 'Monoscan Anti-A, B, AB, D IgG+IgM',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4X10ML', Code: 'IMO1' }
  ]
}, {
  Name: 'Monoscan Anti-A, B, D IgG+IgM',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '3X10ML', Code: 'IMO3' }
  ]
}, {
  Name: 'Monoscan Anti-AB',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IM06' }
  ]
}, {
  Name: 'Monoscan Anti-B',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IMO5' }
  ]
}, {
  Name: 'Monoscan Anti-D IgG+IgM',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IMO2' }
  ]
}, {
  Name: 'Bovine Albumin',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IM32' }
  ]
}, {
  Name: 'Anti-Human Globulin',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IM11' }
  ]
}, {
  Name: 'Anti C',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'IM08' }
  ]
}, {
  Name: 'Anti c',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'IMO9' }
  ]
}, {
  Name: 'Anti E',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'IM12' }
  ]
}, {
  Name: 'Anti e',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'IM13' }
  ]
}, {
  Name: 'Anti Kell',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'IM2O' }
  ]
}, {
  Name: 'Anti C, c, E, e, Kell',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5X5ML', Code: 'IMO7' }
  ]
}

  , {
  Name: 'Anti H',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5ML', Code: 'IM16' }
  ]
}
  , {
  Name: 'Anti RH-CONTROL',
  tags: ['BLOOD GROUPING & PHENOTYPING REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: '10ML', Code: 'IM25' }
  ]
}
  , {
  Name: 'HIV S/P Strip',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE07' }
  ]
}, {
  Name: 'HBsAg S/P Strip',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE04' }
  ]
}, {
  Name: ' HCV S/P/W Strip',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE05' }
  ]
}, {
  Name: 'Svphilis S/P/W Strip',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE10' }
  ]
}, {
  Name: ' Pregnancy Test (Test de Grossesse) Urine Midstream',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE03' }
  ]
}, {
  Name: 'Pregnancy Test (Test de Grossesse) Urine K7',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE01' }
  ]
}, {
  Name: 'Pregnancy Test (Test de Grossesse) Serum/Urine K7',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE08' }
  ]
}, {
  Name: ' HBsAg S/P K7',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE09' }
  ]
}, {
  Name: 'HCV S/p K7',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE02 ' }
  ]
}, {
  Name: 'Syphilis S/P/W K7',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE06' }
  ]
}, {
  Name: 'HIV 3 lignes S/P/W K7',
  tags: ['Rapid Test Products'],
  Insert: '',
  UnitsAndCode: [{ Unite: '01 Test', Code: 'RTE17' }
  ]
}, {
  Name: 'ComboStik10 Parameters',
  tags: ['Urine Reagent Strips'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'UH.01.01.100' }
  ]
}, {
  Name: 'ComboStik 5 Parameters',
  tags: ['Urine Reagent Strips'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'UH.02.01.100 ' }
  ]
}, {
  Name: 'Urine-H-10 10 Parameters',
  tags: ['Urine Reagent Strips'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'UH.01.01.100 ' }
  ]
}, {
  Name: 'ComboStik10 Parameters',
  tags: ['Urine Strips'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 Tests', Code: 'UH.01.01.100' }
  ]
}, {
  Name: 'Bio-Diff kit (Rapid Coloration)',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '4X100 ml', Code: 'COF001' },
  { Unite: '4X250 ml', Code: 'COF004' },
  { Unite: '4X500 ml', Code: 'COF500A' }
  ]
}, {
  Name: 'BioMount (Eukitt)',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 ml', Code: 'COFOOZ' }
  ]
}, {
  Name: 'Mathylene Blue Reagent',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '225 ml', Code: 'COF006' }, { Unite: '500 ml', Code: 'COFOO7' }, { Unite: '1000 ml', Code: 'COF008' }
  ]
}, {
  Name: 'TB Carbol Fuchsin reagent (Fuchsine Phenique)',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF009' }, { Unite: '225 ml', Code: 'COF010' }, { Unite: '1000 ml', Code: 'COF011' }
  ]
}, {
  Name: 'CitoSpray (Solution for fixation of cytological smears)',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 ml', Code: 'COF013' }
  ]
}, {
  Name: 'Basic Fuchsin Reagent',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF016' }, { Unite: '225 ml', Code: 'COF017' }, { Unite: '1000 ml', Code: 'COF018' }
  ]
}, {
  Name: 'Giemsa solution',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF019' }, { Unite: '1000 ml', Code: 'COF020' }, { Unite: '225 ml', Code: 'COF064' }
  ]
}, {
  Name: 'Hematoxylin H (Harris)',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF021' }, { Unite: '1000 ml', Code: 'COF022' }, { Unite: '225 ml', Code: 'COF061' }
  ]
}, {
  Name: 'Immersion Oil',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '100 ml', Code: 'COF025' }
  ]
}, {
  Name: `Lugol's Solution`,
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '1000 ml', Code: 'COF028' }, { Unite: '500 ml', Code: 'COF029' }, { Unite: '225 ml', Code: 'COF030' }
  ]
}, {
  Name: `Hematoxylin M (Mayer's)`,
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF031' }, { Unite: '1000 ml', Code: 'COF032' }
  ]
}, {
  Name: 'May-Gruenwald Solution',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF033' }, { Unite: '1000 ml', Code: 'COF034' }, { Unite: '225 ml', Code: 'COF062' }
  ]
}, {
  Name: 'EA 50 Reagent, Pap 3B',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF037' }, { Unite: '1000 ml', Code: 'COF038' }, { Unite: '225 ml', Code: 'COF063' }
  ]
}, {
  Name: 'OG-6 Reagent, Pap 2A',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF039' }, { Unite: '1000 ml', Code: 'COF040' }, { Unite: '225 ml', Code: 'COF064' }
  ]
}
  , {
  Name: 'Crystal Violet Reagent',
  tags: ['Stains'],
  Insert: '',
  UnitsAndCode: [{ Unite: '500 ml', Code: 'COF048' }, { Unite: '1000 ml', Code: 'COF046' }, { Unite: '225 ml', Code: 'COF047' }
  ]
}, {
  Name: 'ASO TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TL01.40.010', Code: 'TL01.40.010' }
  ]
}, {
  Name: 'CRP TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TLO2.40.010', Code: 'TLO2.40.010' }
  ]
}, {
  Name: 'Microalbumin TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TLO3.40.010', Code: 'TLO3.40.010' }
  ]
}, {
  Name: 'RF TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TLO4.40.010', Code: 'TLO4.40.010' }
  ]
}, {
  Name: 'Ferritin TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TL05.40.010', Code: 'TL05.40.010' }
  ]
}, {
  Name: 'D-Dimer TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TLO6.30.010', Code: 'TLO6.30.010' }
  ]
}, {
  Name: 'HbAlc TurbiLatex',
  tags: ['TURBILATEX REAGENTS'],
  Insert: '',
  UnitsAndCode: [{ Unite: 'TLO7.30.010', Code: 'TLO7.30.010' }
  ]
}, {
  Name: 'AMIKACIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110124' }
  ]
}, {
  Name: 'AMOXYCILLIN 25',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110132' }
  ]
}, {
  Name: 'AMOXYCILLIN/CLAVULAN ACID 20/10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110116' }
  ]
}, {
  Name: 'AMPICILLIN 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110122' }
  ]
}, {
  Name: 'AZITHROMYCIN 15',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110145' }
  ]
}, {
  Name: 'AZTREONAM 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110146' }
  ]
}, {
  Name: 'CARBENICILLIN 100',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113017' }
  ]
}, {
  Name: 'CEFACLOR 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113049' }
  ]
}, {
  Name: 'CEFEPIME 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113002' }
  ]
}, {
  Name: 'CEFIXIME 5',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113058' }
  ]
}, {
  Name: 'CEFOPERAZONE 75',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113074' }
  ]
}, {
  Name: 'CEFOTAXIME 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113018' }
  ]
}, {
  Name: '',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: '' }
  ]
}, {
  Name: 'CEFOXITIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113133' }
  ]
}, {
  Name: 'CEFTAZIDIME 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113013' }
  ]
}, {
  Name: 'CEFTRIAXONE 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113056' }
  ]
}, {
  Name: 'CEFUROXIME 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113050' }
  ]
}, {
  Name: 'CEPHALEXIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113132' }
  ]
}, {
  Name: 'CEPHALOTIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113012' }
  ]
}, {
  Name: 'CEPHAZOLIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113054' }
  ]
}, {
  Name: 'CHLORAMPHENICOL 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113016' }
  ]
}, {
  Name: 'CIPROFLOXACIN 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E191820' }
  ]
}, {
  Name: 'CLINDAMYCIN 2',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113022' }
  ]
}, {
  Name: 'COLISTIN SULPHATE 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113114' }
  ]
}, {
  Name: 'COTRIMOXAZOLE23,75/1,25',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E113122' }
  ]
}, {
  Name: 'DOXYCYCLINE 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111412' }
  ]
}, {
  Name: 'ERTAPENEM 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110502' }
  ]
}, {
  Name: 'ERYTHROMYCIN 15',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110515' }
  ]
}, {
  Name: 'VANCOMYCIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E112023' }
  ]
}, {
  Name: 'FOSFOMYCIN 50',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E115183' }
  ]
}, {
  Name: 'FUSIDIC ACID 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110617' }
  ]
}, {
  Name: 'GENTAMICIN 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110712' }
  ]
}, {
  Name: 'IMIPENEM 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110913' }
  ]
}, {
  Name: 'KANAMYCIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111112' }
  ]
}, {
  Name: 'LEVOFLOXACIN 5',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110106' }
  ]
}, {
  Name: 'LINCOMYCIN 2',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111212' }
  ]
}, {
  Name: 'METRONIDAZOLE 50',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111328' }
  ]
}, {
  Name: 'NALIDIXIC ACID 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111426' }
  ]
}, {
  Name: 'NITROFURANTOIN 300',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111432' }
  ]
}, {
  Name: 'NITROXOLINE 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E191832' }
  ]
}, {
  Name: 'OFLOXACIN 5',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111516' }
  ]
}, {
  Name: 'OXACILLIN 1',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111515' }
  ]
}, {
  Name: 'PEFLOXACIN 5',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111634' }
  ]
}, {
  Name: 'PENICILLIN G10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111614' }
  ]
}, {
  Name: 'PIPEMID ACID 20',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111620' }
  ]
}, {
  Name: 'PIPERACILLIN 100',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111616' }
  ]
}, {
  Name: 'PIPERACILLIN/TAZOBACTAM100/10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110103' }
  ]
}, {
  Name: 'RIFAMPICIN 5',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111816' }
  ]
}, {
  Name: 'SPECTINOMYCIN100',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111922' }
  ]
}, {
  Name: 'SPIRAMYCIN 100',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E110113' }
  ]
}, {
  Name: 'STREPTOMYCIN 300',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E111946' }
  ]
}, {
  Name: 'TEICOPLANIN 30',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E112022' }
  ]
}, {
  Name: 'TETRACYCLINE 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E191836' }
  ]
}, {
  Name: 'TICARCILLIN 75',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E112031' }
  ]
}, {
  Name: 'TICARCILLIN/CLAVULANIC ACID 75/10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E112033' }
  ]
}, {
  Name: 'TOBRAMYCIN 10',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E112020' }
  ]
}, {
  Name: 'TRIMETHOPRIM 1,25',
  tags: ['Antibiotic Sensitivity Discs'],
  Insert: '',
  UnitsAndCode: [{ Unite: '5x50 Discs', Code: 'E112042' }
  ]
}

]

export default {
  productList

}